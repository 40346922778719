import React from 'react';
import { SharedStyleTypes, Typography } from '@commonsku/styles';
import useFolderActionModal from './useFolderActionModal';

export interface DeleteFolderProps {
}

export const DeleteFolder = ({ ...props }: DeleteFolderProps & SharedStyleTypes) => {
    const [popup, setOpen, , setType] = useFolderActionModal();

    return <>
        <Typography style={{ color: 'red', display: 'inline-block' }} onClick={() => {
            setType('delete');
            setOpen(true);
        }}>Delete Folder</Typography>
        {popup}
    </>;
};
