import React from 'react';
import { colors, SharedStyleTypes, Typography } from '@commonsku/styles';
import useFolderActionModal from './useFolderActionModal';

export interface RenameFolderProps {
}

export const RenameFolder = ({ ...props }: RenameFolderProps & SharedStyleTypes) => {
    const [popup, setOpen, , setType] = useFolderActionModal();

    return <>
        <Typography style={{ color: colors.teal[60], marginRight: '2rem', display: 'inline-block' }} onClick={() => {
            setType('rename');
            setOpen(true);
        }}>Rename Folder</Typography>
        {popup}
    </>;
};
