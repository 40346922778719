import React, { useCallback } from 'react';
import { Col, Row, Artwork, Dropzoned, Button, FolderIcon, Typography, colors, ImageIcon } from '@commonsku/styles';
import { getImageSrc, truncateName } from '../../utils';
import { useArtworkLibraryContext } from './context';
import { DroppableFolder } from './DroppableFolder';
import { DraggableFile } from './DraggableFile';
import { CustomDragLayer } from './CustomDragLayer';
import ArtworkLibraryContainer from './ArtworkLibraryContainer';
import { FileInProgress } from './FileInProgress';
import { FileControls } from './FileControls';
import { FolderContainer, NavContainerUl } from './Containers';
import { NewFolder } from './NewFolder';
import { DeleteFolder } from './DeleteFolder';
import { ImageFromWeb } from './ImageFromWeb';
import { CopyToClient } from './CopyToClient';
import { RenameFolder } from './RenameFolder';
import { SortableFolder } from './SortableFolder';

const ArtworkLibrary = () => {
    const {
        isModal,
        fileRef,
        activeFolder,
        activeFiles,
        parents,
        activeParent,
        setActiveFolder,
        setActiveParent,
        sortableFolders,
        onMove,
        onDrop,
        uploadFile,
        uploadFileWeb,
        activeFilesInProgress,
        moveFile,
        isWeb,
        setIsWeb,
    } = useArtworkLibraryContext();

    const getFolderItem = useCallback((item) => {
        const isActive = item.folder_id === activeFolder?.folder_id || !activeFolder && !item.folder_id;
        return <a
            href="/"
            className={'nav-folder' + (isActive ? ' active' : '')}
            onClick={(e) => {
                e.preventDefault();
                setActiveFolder(item);
            }}
        >
            <FolderContainer><FolderIcon color={isActive ? 'white' : colors.teal[70]} /> {item.folder_name}</FolderContainer>
        </a>;
    }, [activeFolder, setActiveFolder]);

    const fileInProgressList = activeFilesInProgress.map((v, index) => {
        return <Col xl={2} lg={2} sm={4} xs padded key={index}>
            <div>
                <FileInProgress fileName={v.fileName} progress={v.progress} />
            </div>
        </Col>;
    });

    const fileList = activeFiles.map((v, index) => {
        return <Col xl={2} lg={2} sm={4} xs padded key={v.file_id}>
            <DraggableFile
                index={index}
                file={v}
                type={'file'}
                key={v.file_id}
            >
                <div ref={fileRef}>
                    <FileControls file={v}>
                        <Artwork name={truncateName(v.file_display_name, 20)}
                            picture={getImageSrc(v, 'medium')}
                            edit={v.edit}
                            noTruncate={true}
                        />
                    </FileControls>
                </div>
            </DraggableFile>
        </Col>;
    });

    let mainContent = (
        <Row>
            {fileInProgressList}
            {fileList}
        </Row>
    );

    // Padding all files and new folder
    let foldersInNav = sortableFolders.filter(f => f && f.parent_type === activeParent.parent_type);

    const list = parents.map((item) => {
        let folderList = null;
        if (item.parent_type === activeParent.parent_type) {
            const sortableFolderList = foldersInNav.map((item, index) => {
                return (
                    <li key={item.folder_id}>
                        <SortableFolder key={item.folder_id} id={item.folder_id} index={index} onMove={onMove} onDrop={onDrop}>
                            <DroppableFolder
                                accept={['file']}
                                lastDroppedItem={null}
                                onDrop={(file) => handleDrop(file, item)}
                                key={item.folder_id}
                            >
                                {getFolderItem(item)}
                            </DroppableFolder>
                        </SortableFolder>

                    </li>
                );
            });
            const allFileFolder = getFolderItem({
                folder_name: 'All Files',
                folder_id: null,
            });
            folderList = <>
                {allFileFolder}
                {sortableFolderList}
                <NewFolder activeParent={activeParent} />
            </>;
        };
        return (
            <li key={item.parent_id}>
                {parents.length > 1 &&
                    <a
                        href="/"
                        className={'nav-parent' + (item.parent_id === activeParent?.parent_id ? ' active' : '')}
                        onClick={(e) => {
                            e.preventDefault();
                            setActiveParent(item);
                            setActiveFolder(null);
                        }}
                    >
                        {item?.parent_type}
                    </a>
                }
                <ul style={{ listStyle: 'none', margin: '0', }}>
                    {folderList}
                </ul>
            </li>
        );
    });

    const handleDrop = useCallback(
        (file, folder) => {
            moveFile(file, folder);
        },
        [moveFile],
    );

    const copyToClient = 'CLIENT' !== activeParent.parent_type ? <CopyToClient /> : null;
    let dropzoneAreaPlaceHolder = (
        <>
            <div className='dropzone-container'>
                <ImageIcon />
                <div className='dropzone-place-holder-text' onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>Drag and drop here to upload and save to this folder</div>
                <Button size='small' onClick={(e) => { }}>Browse Files</Button>
                <Button size='small' onClick={(e) => { e.preventDefault(); e.stopPropagation(); setIsWeb(true); }}>Get from Web</Button>
            </div>
            {copyToClient}
        </>

    );
    if (isWeb) {
        dropzoneAreaPlaceHolder = <><div className='dropzone-container'>
            <ImageFromWeb callback={async (url) => {
                return await uploadFileWeb(url, activeFolder?.folder_id || null);
            }} />
        </div>{copyToClient}</>;
    }
    if (activeFilesInProgress.length > 0) {
        dropzoneAreaPlaceHolder = <div className='dropzone-container'>Uploading files...</div>;
    }

    const dropzoneArea =
        <Dropzoned
            placeholder={dropzoneAreaPlaceHolder}
            onDrop={(acceptedFiles) => {
                acceptedFiles.forEach(f => {
                    uploadFile(f, activeFolder?.folder_id || null);
                });
            }}
        />;

    return <ArtworkLibraryContainer>
        <CustomDragLayer />
        <div className='navContainer'>
            <NavContainerUl>
                {list}
            </NavContainerUl>
        </div>
        <div style={{ flexGrow: 1, padding: '0 2rem', overflow: 'hidden', overflowY: isModal ? 'hidden' : 'auto', minWidth: '250px' }}>
            <div>
                <div style={{ float: 'right' }}>
                    {activeFolder?.folder_id && <RenameFolder />}
                    {activeFolder?.folder_id && <DeleteFolder />}
                </div>
                <Typography.H5>{activeFolder?.folder_name || 'All Files'}</Typography.H5>
            </div>
            <Row>
                <Col sm={12}>{dropzoneArea}</Col>
            </Row>
            <Row>
                <Col sm={12}>
                    {mainContent}
                </Col>
            </Row>
        </div>
    </ArtworkLibraryContainer>;
};

export default ArtworkLibrary;